<template>
  <v-card class="pa-4 pt-2" v-if="value && value.results && value.years">
    <div class="d-flex align-center">
      <v-subheader>Impagados por canal</v-subheader>
      <v-spacer></v-spacer>
      <!-- <v-select v-if="value && value.years" :items="value.years" v-model="yearChart" dense hide-details filled></v-select> -->
      <v-sheet max-width="300">
        <v-slide-group v-model="year" mandatory show-arrows>
          <v-slide-item
            v-for="y in value.years"
            :value="y"
            :key="y"
            v-slot="{ active, toggle }"
          >
            <v-chip
              small
              outlined
              :class="{ 'v-chip--active': active, 'mx-1': true }"
              @click="toggle"
              label
              >{{ y }}</v-chip
            >
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
    <v-divider class="mb-4" />
    <bar-chart
      v-if="value && value.results != null"
      :options="{
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            usePointStyle: true,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                min: 0,
                precision: 0,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      }"
      :chartdata="value.results"
    />
  </v-card>
</template>

<script>
import Chart from "@/components/charts/index.js";

export default {
  components: {
    BarChart: Chart("BarChart"),
  },
  props: {
    value: { type: Object, optional: false },
    yearChart: { type: Number | String, optional: false },
  },
	computed: {
		year: {
			get() {return this.yearChart},
			set(v) {this.$emit('yearChange', v)} 
		}
	},
  mounted() {
    console.log(this.value.results);
  }
};
</script>

<style>
</style>