var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value && _vm.value.results && _vm.value.years)?_c('v-card',{staticClass:"pa-4 pt-2"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-subheader',[_vm._v("Impagados por canal")]),_c('v-spacer'),_c('v-sheet',{attrs:{"max-width":"300"}},[_c('v-slide-group',{attrs:{"mandatory":"","show-arrows":""},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}},_vm._l((_vm.value.years),function(y){return _c('v-slide-item',{key:y,attrs:{"value":y},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{class:{ 'v-chip--active': active, 'mx-1': true },attrs:{"small":"","outlined":"","label":""},on:{"click":toggle}},[_vm._v(_vm._s(y))])]}}],null,true)})}),1)],1)],1),_c('v-divider',{staticClass:"mb-4"}),(_vm.value && _vm.value.results != null)?_c('bar-chart',{attrs:{"options":{
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
        },
      },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              min: 0,
              precision: 0,
            },
          } ],
        xAxes: [
          {
            stacked: true,
          } ],
      },
    },"chartdata":_vm.value.results}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }